/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  
  // About Us "Read More"
  // >> Mobile Version
  function readmoreSlideToggleMobile() {
    var readmore = jQuery('.text-wrapper');
    readmore.find('.readmore-content.mobile').hide();
    readmore.find('a.readmore').click( function () {
      if ($(this).html()[0] === '+') {
        $(this).html('- READ MORE');
      } else {
        $(this).html('+ READ MORE');
      }
      jQuery(this).parent().parent().find('.readmore-content.mobile').slideToggle();
      jQuery(jQuery(this).parent().parent().find('.readmore-content.mobile')).find('.readmore-content.desktop').slideDown();
      return false;
    });
  }
  // >> Desktop Version
  function readmoreSlideToggleDesktop() {
    var readmore = jQuery('.text-wrapper');
    readmore.find('.readmore-content.desktop').hide();
    readmore.find('a.readmore').click( function () {
      if ($(this).html()[0] === '+') {
        $(this).html('- READ MORE');
        jQuery(this).parent().parent().parent().parent().css({'background-color':'transparent',height:'auto'});
        jQuery(this).parent().parent().parent().css({transform:'none',top:'0'});
      } else {
        $(this).html('+ READ MORE');
        $(jQuery(this).parent().parent().parent().parent())[0].style.height = '';
        $(jQuery(this).parent().parent().parent().parent())[0].style.backgroundColor = '#c5d2d2';
        $(jQuery(this).parent().parent().parent())[0].style.transform = '';
        $(jQuery(this).parent().parent().parent())[0].style.top = '';
      }

      
      // Toggle .readmore-content div
      jQuery(this).parent().parent().parent().find('.readmore-content.desktop').slideToggle();
      return false;
    });
  }
  
  // Responsive Image Map
  //$('img[usemap]').rwdImageMaps();

  function checkForViewportChange () {
    var state = window.getComputedStyle(document.body,':before').content;
    this.lastState = this.lastState || "";
    if (state !== this.lastState) {
    if ((state === '\"mobile\"') || (state === 'mobile')) {
      readmoreSlideToggleMobile();
      // Footer: add spacer after "Employment" and push links to next line
      //$('footer#site-footer #footer-navigation li#menu-item-117').addClass('remove-divider').after('<li class="visible-xs-block remove-divider"></li>');
    } else if ((state === '\"tablet\"') || (state === 'tablet')) {
      readmoreSlideToggleMobile();
    } else if ((state === '\"desktop\"') || (state === 'desktop')) {
      $('footer#site-footer p.copyright').insertAfter('footer#site-footer .left nav#footer-navigation');
      //$('footer#site-footer ul.social').insertAfter('footer#site-footer .left nav#footer-navigation');
      readmoreSlideToggleDesktop();
    }
    this.lastState = state;
  }}
  window.setInterval (checkForViewportChange, 150);

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
				
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Gallery
    'gallery': {
      init: function() {
        $(document).ready(function() {
          
          var $owl = $('.owl-carousel');
          $owl.trigger('destroy.owl.carousel');
          // After destory, the markup is still not the same with the initial.
          // The differences are:
          //   1. The initial content was wrapped by a 'div.owl-stage-outer';
          //   2. The '.owl-carousel' itself has an '.owl-loaded' class attached;
          //   We have to remove that before the new initialization.
          $owl.html($owl.find('.owl-stage-outer').html()).removeClass('owl-loaded');
          $owl.owlCarousel({
            loop: false,
            dots: false,
            responsiveClass: true,
            thumbs: true,
            thumbImage: true,
            nav: true,
            navText: ['<img src="../wp-content/themes/sierrafitness/dist/images/img-arrow-left.png">', '<img src="../wp-content/themes/sierrafitness/dist/images/img-arrow-right.png">'],
            items: 1,
            //thumbsPrerendered: true,
            thumbContainerClass: 'owl-thumbs hidden-xs hidden-sm',
            thumbItemClass: 'owl-thumb-item',
            itemElement: 'div'
          });
          
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
